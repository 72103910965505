import React from 'react';
import './App.css';
import AlternativeTable from './components/AlternativeTable/AlternativeTable';

function App() {
  return (
    <div style={{ backgroundColor: 'white', color: '#f97272', padding: '1rem' }}>
      <h1 style={{ textAlign: 'center' }}>AltDest</h1>
      <p style={{ textAlign: 'center' }}>Find alternatives to overtouristed destinations</p>
      <AlternativeTable />
    </div>    
  );
}

export default App;
