import React from 'react';

const AlternativeTable = () => {
  return (
    <div style={{ width: '100%', height: '533px', border: '1px solid #ccc' }}>
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/appouMkzZm2Q7BiJE/shrTr7a8BgwinmK4C?backgroundColor=gray&viewControls=on"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="100%"
        title='alternatives-table'
        style={{ background: 'transparent' }}
      ></iframe>
    </div>
  );
};

export default AlternativeTable;
